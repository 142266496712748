import request from '@/utils/request';
export var defaultProductData = {
  id: 0,
  enabled: true,
  name: null,
  sku: '',
  productType: 'variable',
  priority: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  allowBazarDhara: false,
  bazarDharaDiscount: 0,
  isfreightAllowed: true,
  suppliedBy: '',
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  hsnCode: null,
  videoUrl: null,
  manageStock: false,
  stockStatus: 'In Stock',
  bidAllowed: 90,
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  unitOfMeasurementId: null,
  category: [],
  attribute: [],
  productsImage: [],
  productsVariation: []
};
export var getProducts = function getProducts(params) {
  return request({
    url: '/products',
    method: 'get',
    params: params
  });
};
export var getProductById = function getProductById(id, params) {
  return request({
    url: "/products/".concat(id),
    method: 'get',
    params: params
  });
};
export var updateProduct = function updateProduct(id, data) {
  return request({
    url: "/products/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteProduct = function deleteProduct(id) {
  return request({
    url: "/products/".concat(id),
    method: 'delete'
  });
};
export var createProduct = function createProduct(data) {
  return request({
    url: '/products/',
    method: 'post',
    data: data
  });
};
export var productCount = function productCount(params) {
  return request({
    url: '/products/count',
    method: 'get',
    params: params
  });
};
export var updateProductCategories = function updateProductCategories(id, data) {
  return request({
    url: "/products/categories/".concat(id),
    method: 'patch',
    data: data
  });
};
export var updateProductAttributes = function updateProductAttributes(id, data) {
  return request({
    url: "/products/attributes/".concat(id),
    method: 'patch',
    data: data
  });
};
export var updateProductVariations = function updateProductVariations(data) {
  return request({
    url: '/products-variations/manage',
    method: 'post',
    data: data
  });
};
export var deleteProductVariation = function deleteProductVariation(id) {
  return request({
    url: "/products-variations/".concat(id),
    method: 'delete'
  });
};
export var updateProductImages = function updateProductImages(id, data) {
  return request({
    url: "/products/images/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteProductImages = function deleteProductImages(id) {
  return request({
    url: "/products-images/".concat(id),
    method: 'delete'
  });
};
export var updateProductInventories = function updateProductInventories(id, data) {
  return request({
    url: "/products/inventories/".concat(id),
    method: 'patch',
    data: data
  });
};
export var uploadProductImages = function uploadProductImages(data) {
  return request({
    url: '/products/images',
    method: 'post',
    data: data
  });
};
export var getProductsVariations = function getProductsVariations(params) {
  return request({
    url: '/products-variations',
    method: 'get',
    params: params
  });
};