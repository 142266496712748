import request from '@/utils/request';
export var defaultTransporterData = {
  id: 0,
  enabled: true,
  name: undefined,
  contactNumber: undefined,
  line1: undefined,
  line2: null,
  userId: null,
  user: {
    id: null
  },
  countryId: null,
  country: {
    id: null
  },
  stateId: null,
  state: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  },
  pincodeId: null,
  pincode: {
    id: null
  }
};
export var getTransporters = function getTransporters(params) {
  return request({
    url: '/transporters',
    method: 'get',
    params: params
  });
};
export var createTransporter = function createTransporter(data) {
  return request({
    url: '/transporters',
    method: 'post',
    data: data
  });
};
export var getTransporterById = function getTransporterById(id) {
  return request({
    url: "/transporters/".concat(id),
    method: 'get'
  });
};
export var updateTransporter = function updateTransporter(id, data) {
  return request({
    url: "/transporters/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteTransporter = function deleteTransporter(id) {
  return request({
    url: "/transporters/".concat(id),
    method: 'delete'
  });
};