var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-steps",
          { attrs: { active: _vm.activeStep, "align-center": "" } },
          [
            _c("el-step", {
              attrs: { title: "Step 1", description: "Select Products" },
              nativeOn: {
                click: function($event) {
                  return _vm.goToStep(0)
                }
              }
            }),
            _c("el-step", {
              attrs: { title: "Step 2", description: "Add Address" },
              nativeOn: {
                click: function($event) {
                  return _vm.goToStep(1)
                }
              }
            }),
            _c("el-step", {
              attrs: { title: "Step 3", description: "Preview" },
              nativeOn: {
                click: function($event) {
                  return _vm.goToStep(2)
                }
              }
            })
          ],
          1
        ),
        _c("el-divider"),
        _vm.activeStep === 0
          ? _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading"
                      }
                    ],
                    ref: "ordersProductTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.ordersProducts,
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                      size: "mini"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", width: "50" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Product",
                        prop: "productId",
                        width: "250"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      clearable: "",
                                      "remote-method": _vm.getProductList,
                                      loading: _vm.productListLoading,
                                      placeholder: "Select Product",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.selectedVariationId,
                                      callback: function($$v) {
                                        _vm.selectedVariationId = $$v
                                      },
                                      expression: "selectedVariationId"
                                    }
                                  },
                                  _vm._l(_vm.products, function(product) {
                                    return _c(
                                      "el-option-group",
                                      {
                                        key: product.id,
                                        attrs: { label: product.name }
                                      },
                                      _vm._l(
                                        product.productsVariation,
                                        function(item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                label:
                                                  product.name +
                                                  "-" +
                                                  item.name,
                                                value: item.id
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name))
                                              ])
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  }),
                                  1
                                ),
                                _c("el-button", {
                                  attrs: {
                                    disabled: _vm.selectedVariationId === 0,
                                    size: "mini",
                                    type: "success",
                                    icon: "el-icon-plus"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addVariation(scope)
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.product
                                  ? _c(
                                      "strong",
                                      { staticClass: "text-small" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.product.name) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2226549586
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Variation",
                        prop: "productsVariationId",
                        width: "120"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.productsVariation
                                  ? _c(
                                      "strong",
                                      { staticClass: "text-small" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.productsVariation.name
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        750040870
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Quantity",
                        prop: "quantity",
                        width: "160"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    size: "mini",
                                    min: 0,
                                    max: 1000,
                                    step: 1,
                                    "step-strictly": ""
                                  },
                                  model: {
                                    value: scope.row.quantity,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "quantity", $$v)
                                    },
                                    expression: "scope.row.quantity"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4216898269
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Edit Price",
                        prop: "allowPriceEdit",
                        width: "80"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { disabled: scope.row.bidId > 0 },
                                  model: {
                                    value: scope.row.allowPriceEdit,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "allowPriceEdit", $$v)
                                    },
                                    expression: "scope.row.allowPriceEdit"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2059002930
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Unit Price",
                        prop: "unitPrice",
                        width: "160"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    disabled: "",
                                    size: "mini",
                                    min: 0,
                                    max: 1000,
                                    precision: 2,
                                    step: 1
                                  },
                                  model: {
                                    value: scope.row.unitPrice,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "unitPrice", $$v)
                                    },
                                    expression: "scope.row.unitPrice"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1896373582
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Sale Price",
                        prop: "salePrice",
                        width: "160"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    disabled: !scope.row.allowPriceEdit,
                                    size: "mini",
                                    min: 0,
                                    max: 1000,
                                    precision: 2,
                                    step: 1
                                  },
                                  model: {
                                    value: scope.row.salePrice,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "salePrice", $$v)
                                    },
                                    expression: "scope.row.salePrice"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2343483258
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", width: "80" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    circle: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeProduct(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1756440040
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("br"),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "success" },
                        on: { click: _vm.saveProducts }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "text-small text-italic",
                        attrs: { color: "grey" }
                      },
                      [_vm._v(" (save after modifications) ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.activeStep === 1
          ? _c(
              "div",
              [
                _vm.formData.status !== "Delivery Confirmed"
                  ? _c(
                      "el-form",
                      {
                        ref: "formData",
                        staticClass: "demo-form",
                        attrs: {
                          model: _vm.formData,
                          "status-icon": "",
                          rules: _vm.rules,
                          "label-position": "left"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Billing Address",
                              prop: "addressId"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  name: "addressId",
                                  placeholder: "Select Address"
                                },
                                model: {
                                  value: _vm.formData.addressId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "addressId", $$v)
                                  },
                                  expression: "formData.addressId"
                                }
                              },
                              _vm._l(_vm.addressList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    required: "",
                                    label: item.name,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Shipping Address",
                              prop: "shippingId"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  name: "shippingId",
                                  placeholder: "Select Address"
                                },
                                model: {
                                  value: _vm.formData.shippingId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "shippingId", $$v)
                                  },
                                  expression: "formData.shippingId"
                                }
                              },
                              _vm._l(_vm.addressList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    required: "",
                                    label: item.name,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Transporter",
                              prop: "transporterId"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  name: "transporterId",
                                  placeholder: "Select Transporter"
                                },
                                model: {
                                  value: _vm.formData.transporterId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "transporterId", $$v)
                                  },
                                  expression: "formData.transporterId"
                                }
                              },
                              _vm._l(_vm.transporterList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    required: "",
                                    label: item.name,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Comments", prop: "comments" } },
                          [
                            _c("el-input", {
                              attrs: {
                                name: "comments",
                                placeholder: "Comments"
                              },
                              model: {
                                value: _vm.formData.comments,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "comments", $$v)
                                },
                                expression: "formData.comments"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading"
                                  },
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                attrs: { type: "success" },
                                on: { click: _vm.submitForm }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("global.form.save")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.activeStep === 2
          ? _c(
              "div",
              [
                _c("order-info-tab", { attrs: { data: _vm.formData } }),
                _c("order-user-tab", { attrs: { data: _vm.formData } }),
                _vm.formData.status === "in cart"
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading"
                              },
                              { name: "waves", rawName: "v-waves" }
                            ],
                            attrs: { type: "success" },
                            on: { click: _vm.placeOrder }
                          },
                          [_vm._v(" Place Order ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("br")
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }